import { Link, useMatches } from "@remix-run/react";
import { Box, Flex, Text } from "sparta";

const FOOTER_HIDDEN_PAGE_IDS = [
  "routes/access.login",
  "routes/access.register.set",
  "routes/access.register.otp",
  "routes/access.register.personalData",
  "routes/access.register.billing-success",
  "routes/access.reset-password",
  "routes/access.reset-password.set",
  "routes/access.reset-password.$resetToken",
  "routes/$platform.posts.$postId",
  "routes/$platform.events.$eventId",
];

export default function Footer() {
  const matches = useMatches();
  if (FOOTER_HIDDEN_PAGE_IDS.includes(matches.at(-1)?.id)) {
    return null;
  }
  return (
    <footer>
      <Flex
        justify="between"
        align="center"
        className="footer-container"
      >
        <Box className="link-container">
          <Link to="/legal/community-member-guidelines/" className="footer-link">
          Community and Member Guidelines
          </Link>
          <Link to="/legal/privacy-policy/" className="footer-link">
          Privacy Policy
          </Link>
          <Link to="/contact" className="footer-link">
          Contact Support
          </Link>
          <Link to="https://g2xchange.com/corporate-members" className="footer-link">
          Corporate Members
          </Link>
        </Box>
        <Text size="1" className="footer-text">
          © {new Date().getFullYear()} G2Xchange. All rights reserved.
        </Text>
      </Flex>
    </footer>
  );
}
