import { CalendarIcon, HomeIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Flex } from "sparta";
import {
  CompaniesIcon,
  DefenseIcon,
  FedCivIcon,
  G2XLogo,
  GovAIQ,
  HealthIcon,
  OpportunitiesIcon,
  PeopleIcon,
} from "~/icons";
import { useRootLoaderData } from "~/root";
import { CollapsibleSection } from "./CollapsibleSection";
import { MenuButton } from "./MenuButton";
import { ResponsiveSideNavContainer } from "./ResponsiveSideNavContainer";
import { Link } from "@remix-run/react";

interface SideNavProps {
  children: React.ReactNode;
}

export function SideNav({ children }: SideNavProps) {
  const { canSeeOpportunities } = useRootLoaderData();

  return (
    <Flex gap="0">
      <ResponsiveSideNavContainer>
        <Flex direction="column" width="180px" p="4" style={{ borderRight: "1px solid var(--gray-a6)" }}>
          <Box height="60px">
            <Link to="/" prefetch="intent" className="G2X-logo">
              <G2XLogo />
            </Link>
          </Box>
          <Flex direction="column" gap="4">
            <MenuButton icon={<HomeIcon />} title="Home" to="/" />
            <MenuButton icon={<CalendarIcon />} title="Events" to="/events" />
            <MenuButton icon={<MagnifyingGlassIcon />} title="Search" to="/search_v2" />
            <CollapsibleSection title="Daily Take">
              <MenuButton icon={<HealthIcon />} title="Health" to="/health" />
              <MenuButton icon={<FedCivIcon />} title="FedCiv" to="/fedciv" />
              <MenuButton icon={<DefenseIcon />} title="Defense" to="/defense" />
            </CollapsibleSection>
            {canSeeOpportunities && (
              <CollapsibleSection title="Intelligence">
                <MenuButton icon={<OpportunitiesIcon />} title="Opportunities" to="/opportunities" />
              </CollapsibleSection>
            )}
            <CollapsibleSection title="Directory">
              <MenuButton icon={<PeopleIcon />} title="People" to="/people" />
              <MenuButton
                icon={<CompaniesIcon />}
                title="Companies"
                to="/companies"
                match={["/companies", "/companies/search"]}
              />
            </CollapsibleSection>
            <CollapsibleSection title="TOOLS">
              <MenuButton newTab={true} icon={<GovAIQ />} title="GovAIQ" to="https://govaiq.com/login" />
            </CollapsibleSection>
          </Flex>
        </Flex>
      </ResponsiveSideNavContainer>
      {children}
    </Flex>
  );
}
