import type { Location } from "@remix-run/react";
import type { CurrentRefinementsRenderState } from "instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements";
import qs from "qs";
import type { ReactNode } from "react";
import { Children, isValidElement } from "react";

export function getFirstChildPropValue(
  children: ReactNode,
  propNameCb: (props: any) => string,
): string | string[] | undefined {
  let propValue = undefined;

  Children.forEach(children, (element) => {
    if (!isValidElement(element)) return;
    const propName = propNameCb(element.props);
    if (propName in element.props) {
      propValue = element.props[propName];
      return;
    }
  });

  return propValue;
}

export function capitalize(value: string) {
  if (typeof value !== "string") return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getAttributeRefinements(attribute: string, items: CurrentRefinementsRenderState["items"]) {
  const item = items.find((item) => item.attribute === attribute);
  return item?.refinements || [];
}

export function urlToSearchState(location: Location) {
  const { query = "", tags = [], platforms = [], postType = [], agency = [] } = qs.parse(location.search.slice(1));
  return {
    posts: {
      query: query,
      refinementList: {
        tags: Array.isArray(tags) ? (tags as string[]).map(decodeURIComponent) : [tags].filter(Boolean),
        platforms: Array.isArray(platforms)
          ? (platforms as string[]).map(decodeURIComponent)
          : [platforms].filter(Boolean),
        postType: Array.isArray(postType) ? (postType as string[]).map(decodeURIComponent) : [postType].filter(Boolean),
        agency: Array.isArray(agency) ? (agency as string[]).map(decodeURIComponent) : [agency].filter(Boolean),
      },
    },
  };
}

const createURL = (state) => `?${qs.stringify(state)}`;
export function searchStateToUrl(location, searchState) {
  const indexUiState = searchState["posts"] || {};

  const parsedState = {
    query: indexUiState.query,
    tags: indexUiState.refinementList?.tags,
    platforms: indexUiState.refinementList?.platforms,
    agency: indexUiState.refinementList?.agency,
    postType: indexUiState.refinementList?.postType,
  };

  return parsedState ? `search_v2${createURL(parsedState)}` : "";
}
export function searchStateToUrlHomePage(location, searchState) {
  const indexUiState = searchState["posts"] || {};

  const parsedState = {
    query: indexUiState.query,
    tags: indexUiState.refinementList?.tags,
    platforms: indexUiState.refinementList?.platforms,
    agency: indexUiState.refinementList?.agency,
    postType: indexUiState.refinementList?.postType,
  };

  return parsedState ? `${createURL(parsedState)}` : "";
}
