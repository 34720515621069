import { useLocation, useNavigate } from "@remix-run/react";
import { useMemo, useRef } from "react";
import type { PropsWithChildren } from "react";
import { InstantSearch } from "react-instantsearch";
import { searchClient } from "~/sdk/qs3/typesense";
import { searchStateToUrl, urlToSearchState } from "~/utils/search";

export function Search({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchState = useMemo(() => urlToSearchState(location), [location.search]);
  const debouncedSetStateRef = useRef(null);

  /**
   * Every time the search state changes, we update the URL
   * with the new search state. We debounce the URL update
   * to avoid updating the URL too often.
   *
   * @param updatedSearchState The updated ui state from the InstanSearch component
   */
  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      const url = searchStateToUrl(location, updatedSearchState.uiState);
      if (url !== window.location.href) {
        if (location.pathname?.includes("search_v2") || updatedSearchState?.uiState.posts?.query) {
          navigate(searchStateToUrl(location, updatedSearchState.uiState), {
            replace: true,
          });
        }
      }
    }, 250);

    updatedSearchState.setUiState(updatedSearchState.uiState);
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="posts"
      initialUiState={searchState as any}
      onStateChange={onSearchStateChange}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      stalledSearchDelay={100}
    >
      {children}
    </InstantSearch>
  );
}
