// import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { Button, Flex } from "sparta";
// import { AnimatePresence, motion } from "framer-motion";
// import { useState } from "react";

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
}

export function CollapsibleSection({ title, children }: CollapsibleSectionProps) {
  // const [open, setOpen] = useState(false);

  return (
    <Flex direction="column">
      <Button
        color="gray"
        variant="ghost"
        style={{
          width: "100%",
          justifyContent: "space-between",
          gap: "var(--space-2)",
          background: "none",
          textTransform: "uppercase",
        }}
        // rightIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        // onClick={() => setOpen(!open)}
      >
        {title}
      </Button>
      {/* <AnimatePresence>
        {open ? (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <Flex
              direction="column"
              width="100%"
              height="100%"
              flexGrow="0"
              overflow="hidden"
              p="2"
              gap="4"
            >
              {children}
            </Flex>
          </motion.div>
        ) : null}
      </AnimatePresence> */}
      <Flex direction="column" width="100%" height="100%" flexGrow="0" overflow="hidden" p="2" gap="4">
        {children}
      </Flex>
    </Flex>
  );
}
