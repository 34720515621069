import { CacheLocation, FpjsProvider, useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { createContext, useContext, useState } from "react";
import { useRootLoaderData } from "~/root";

interface FingerprintContextProps {
  fingerprint: string | null;
  loading: boolean;
  error: string;
  loadFingerprint: () => Promise<string>;
}

const FingerprintContext = createContext<FingerprintContextProps>({
  fingerprint: "",
  loading: false,
  error: "",
  loadFingerprint: async () => "",
});

function FingerprintContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [visitorId, setVisitorId] = useState<string | null>();
  const { user } = useRootLoaderData();
  const { isLoading, getData, error } = useVisitorData({}, { immediate: false });

  async function loadFingerprint() {
    try {
      if (user?.id) {
        setVisitorId(null);
        return null;
      }

      if (visitorId) {
        return visitorId;
      }

      const visitorData = await getData();
      setVisitorId(visitorData.visitorId);
      return visitorData.visitorId;
    } catch (error) {
      // Return a default value if an error occurs
      setVisitorId("general-visitor");
      return "general-visitor";
    }
  }

  return (
    <FingerprintContext.Provider
      value={{
        fingerprint: visitorId,
        loading: isLoading,
        error: error?.message,
        loadFingerprint,
      }}
    >
      {children}
    </FingerprintContext.Provider>
  );
}

export function FingerprintProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <FpjsProvider
      cacheLocation={CacheLocation.LocalStorage}
      cachePrefix="g2x-fpjs-"
      cacheTimeInSeconds={60 * 60 * 24}
      loadOptions={{
        apiKey: ENV.FINGERPRINT_PUBLIC_API_KEY,
        endpoint: ["https://metrics.g2xchange.com/bp-MoiRoLfHXr/rp-wspThYAV9D?region=us"],
        scriptUrlPattern: [
          "https://metrics.g2xchange.com/bp-MoiRoLfHXr/ad-Ke0PVOXM7E?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
        ],
      }}
    >
      <FingerprintContextProvider>{children}</FingerprintContextProvider>
    </FpjsProvider>
  );
}

export function useFingerprint() {
  const context = useContext(FingerprintContext);
  if (!context) {
    throw new Error("useFingerprint must be used within a FingerprintProvider");
  }
  return context;
}
